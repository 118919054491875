<template>
	<div>

		
		<div class="grid grid-cols-2 mb-4">
			<div class="col-span-1">
				<h2 class="text-2xl font-bold">Pending Loans</h2>
			</div>
			<div class="col-span-1">
				<div class="relative">
					<input
						type="search"
						name="search"
						class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-14 w-full"
						placeholder="Search for company’s name, status, etc."
						v-model="searchQuery">
					<ion-icon name="search-outline" class="text-blue-200 absolute z-10 text-lg ml-3 left-0" style="top: 50%; transform: translateY(-50%);"></ion-icon>
				</div>
			</div>
		</div>

		<div class="border border-solid border-blue-200">
			<div class="flex">
				<div class="duration-300" :class="{'w-full': !isOpen, 'w-1/2 md:w-3/5 lg:w-1/2 xl:w-3/5': isOpen}">
					<datatable
						:data="loans"
						:columns="columns"
						:query="searchQuery"
						:actions="actions"
						:className="tableRowClassName"
						:loading="loading"
						:onClick="click"
						ref="table"
					/>
				</div>
				<div class="w-1/2 md:w-3/5 lg:w-1/2 xl:w-3/5 px-8 py-10 border-l border-blue-200" v-if="isOpen">
					<div class="h-full overflow-y-auto">
						
						<div class="animated animation-delay-300 fadeIn">

							<template v-if="loan.status == 'pending'">
								<h3 class="text-lg font-bold mb-4">Take Action:</h3>
								<div class="flex flex-row flex-wrap">
									<button
										class="button flex items-center pl-4 border border-blue-500 hover:bg-blue-500 text-blue-500 hover:text-white mr-5"
										@click.prevent="approve"
									>
										<ion-icon name="checkmark-outline" class="text-xl mr-2"></ion-icon>
										<span>Approve</span>
									</button>
									<button class="button flex items-center pl-4 border border-red-500 hover:bg-red-500 text-red-500 hover:text-white">
										<ion-icon name="close-outline" class="text-xl mr-2"></ion-icon>
										<span>Decline</span>
									</button>
								</div>
							</template>

						</div>
						
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				searchQuery: '',
				company: null,
				loading: false,
				loans: [],
				loan: null,
				columns: [
					{
						name: 'user',
						th: 'Customer Name',
						render: (loan, user) => `${user.name} ${user.last_name}`
					},
					{
						name: 'company_name',
						th: 'Company Name',
						render: loan => loan.user?.company?.name
					},
					{
						name: 'loan_amount',
						th: 'Loan Amount',
					},
					{
						name: 'status',
						th: 'Status',
						render: (company, status) => {
							switch (status) {
								case 'approved':
									return `<div class="badge badge-green-soft-outline">
										Confirmed
									</div>`;
								case 'disbursed':
									return `<div class="badge badge-green-soft-outline">
										Disbursed
									</div>`;
								case 'declined':
									return `<div class="badge badge-red-soft-outline">
										Declined
									</div>`;
								case 'paid':
									return `<div class="badge badge-green-soft-outline">
										Paid
									</div>`;
								case 'pending':
								default:
									return `<div class="badge badge-orange-soft-outline">
										Pending
									</div>`;
							}
						}
					},
					{
						name: 'created_at',
						th: 'Request Date',
						render: (loan, created_at) => this.$options.filters.dateFormat(created_at, 'D, dd M Y h:ia')
					}
				],
				actions: [
					{
						text: 'Decline Loan',
						color: 'red-500',
						action: this.decline
					},
					{
						text: 'Delete Request',
						action: this.delete
					},
				]
			}
		},
		computed: {
			isOpen() {
				return !!this.loan
			},
		},
		beforeMount() {
			this.getLoans();
		},
		methods: {
			async approve(loan) {
				this.approving = true;
				await this.$post({
					url: `${this.$baseurl}/staff/loans/approve`,
					data: {
						loan_id: loan.id
					},
					headers: this.headers,
					success: response => {
						this.loans = response.data.data;
					}
				});
				this.approving = false;
			},
			click(loan) {
				this.loan = this.loan === loan ? null : loan;
				this.$refs.table.renderData();
			},
			async getLoans() {
				this.loading = true;
				await this.$get({
					url: `${this.$baseurl}/loans/pending`,
					headers: this.headers,
					success: response => {
						this.loans = response.data.data;
					}
				});
				this.loading = false;
			},
			tableRowClassName(row) {
				return row.id === this.loan?.id ? 'selected' : '';
			},
		}
	}
</script>